<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="nurseries"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            Nurseries
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            max-width="500"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New Nursery
              </v-btn>
            </template>
            <NewNurseryForm
              @created="handleCreatedNursery"
              @canceled="handleCanceledNursery"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`/admin/nurseries/${item._id}`"
          @delete="deleteNursery(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import { mapActions } from 'vuex'
import NewNurseryForm from './NurseryForm'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'
import { nurseryFields } from './constants'

export default {
  name: 'AdminNursery',
  components: {
    NewNurseryForm,
    EditAndDeleteButtons
  },
  data () {
    const nurseryColumns = nurseryFields.map(({ name, label, suffix }) => {
      const text = label + (suffix ? ` (${suffix})` : '')
      return {
        text,
        value: name
      }
    })
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'CC - Nursery Id',
          value: 'ccNurseryId'
        },
        {
          text: 'Nursery Categories',
          value: 'nurseryCategories'
        },
        ...nurseryColumns,
        {
          text: 'Notes',
          value: 'notes'
        },
        {
          text: 'Citations',
          value: 'citations'
        },
        {
          value: 'actions',
          sortable: false
        }
      ],
      nurseries: [],
      showForm: false
    }
  },
  async created () {
    await this.fetchNurseryCategories()
    await this.fetchNurseries()
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async fetchNurseryCategories () {
      const { data: nurseryCategories } = await this.$axios.get('/nursery_categories')
      this.nurseryCategories = nurseryCategories.map(nurseryCategory => ({
        ...nurseryCategory
      }))
    },
    async fetchNurseries () {
      const { data: nurseries } = await this.$axios.get('/nurseries')
      const mergedNurseries = nurseries.map((nursery) =>
        ({
          ...nursery,
          nurseryCategories: this.nurseryCategories.filter((category) => nursery.nurseryCategories?.includes(category._id))?.map((category) => category.name)
        }))
      this.nurseries = mergedNurseries.map(nursery => ({
        ...nursery
      }))
    },
    async deleteNursery (id) {
      try {
        await this.$axios.delete(`/nurseries/${id}`)
        await this.fetchNurseries()
      } catch (err) {
        this.showSnackbar({ color: 'error', message: 'Unable to delete nursery. Please try again.' })
        throw err
      }
    },
    handleCanceledNursery () {
      this.showForm = false
    },
    handleCreatedNursery () {
      this.showForm = false
      this.fetchNurseries()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
